import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useContextMenu } from 'context/ContextMenuContext';

const ContextMenu = () => {
  const menuRef = useRef();

  const { contextMenuState: { isVisible, position, items }, hideContextMenu } = useContextMenu();
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [xPosition, setXPosition] = useState('-1000px');
  const [yPosition, setYPosition] = useState('-1000px');


  const handleClickOutside = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)
      && !(Math.abs(e.clientX - position.x) < 5 && Math.abs(e.clientY - position.y) < 5)
    ) {
      hideContextMenu();
    }
  }

  useEffect(() => {
    if (isVisible && !initialRender) {
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }
  }, [isVisible, initialRender]);

  useEffect(() => {
    if (isVisible && initialRender && menuRef.current) {
      const height = menuRef.current.offsetHeight;
      const x = position.x + 3;
      const y = position.y - height / 2;
      setInitialRender(false);
      setXPosition(x + 'px');
      setYPosition(y + 'px');
    }
  }, [isVisible, initialRender]);

  useEffect(() => {
    if (!isVisible) {
      setHighlightedIndex(null);
      setInitialRender(true);
      setXPosition('-1000px');
      setYPosition('-1000px');
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <ul
      ref={menuRef}
      style={{
        position: 'fixed',
        top: initialRender ? '-1000px' : yPosition,
        left: initialRender ? '-1000px' : xPosition,
        visibility: initialRender ? 'hidden' : 'visible',
        listStyleType: 'none',
        padding: '0',
        backgroundColor: 'white',
        border: '1px solid black',
        zIndex: 1000,
      }}
      onMouseLeave={() => setHighlightedIndex(null)}
    >
      {items.map((item, index) => (
        <li
          key={index}
          onClick={(e) => {
            item.onClick(e);
            hideContextMenu();
          }}
          onMouseEnter={() => setHighlightedIndex(index)}
          style={{
            backgroundColor: index === highlightedIndex ? '#2887e3' : '#f2f2f2',
            color: index === highlightedIndex ? 'white' : 'black',
            padding: '5px',
            cursor: 'pointer',
          }}
        >
          {item.label}
        </li>
      ))}
    </ul>
  );
};

export default ContextMenu;