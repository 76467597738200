
import { useFeed } from 'hooks/useFollow';
import useLocalStorage from 'hooks/useLocalStorage';
import { useSync } from 'hooks/useSync';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';


const ThemeContext = createContext();

const themes = [
    'light',
    'grey',
    'dark'
]

export const ThemeContextProvider = ({ children }) => {
    const prefersDarkMode = useMediaQuery({ query: '(prefers-color-scheme: dark)' });
    const [_getTheme, _setTheme] = useSync(prefersDarkMode ? 'dark' : 'light');
    const [prefersSnapping, setPrefersSnapping] = useLocalStorage('thinkmappreferssnapping', true);

    const getTheme = () => {
        return _getTheme() || themes[0];
    }

    const setTheme = (theme) => {
        _setTheme(theme);
        localStorage.setItem('thinkmaptheme', theme);
    };

    const cycleTheme = () => {
        setTheme(themes[(themes.indexOf(getTheme()) + 1) % themes.length]);
    };

    const getBgColor = useFeed(getTheme, (theme) => {
        switch (theme) {
            case 'grey':
                return '#bfbfbf';
            case 'light':
                return 'white';
            case 'dark':
                return '#000';
            default:
                return 'white';
        }
    });

    const getTextColor = useFeed(getTheme, (theme) => {
        switch (theme) {
            case 'grey':
                return '#fff';
            case 'light':
                return '#333';
            case 'dark':
                return '#bfbfbf';
            default:
                return '#000';
        }
    });

    const getHighContrastColor = useFeed(getTheme, (theme) => {
        switch (theme) {
            case 'grey':
                return '#000';
            case 'light':
                return '#000';
            case 'dark':
                return '#fff';
            default:
                return '#fff';
        }
    });

    const getBlackInversion = useFeed(getTheme, (theme) => {
        switch (theme) {
            case 'grey':
                return '1';
            case 'light':
                return '0.2';
            case 'dark':
                return '0.75';
            default:
                return '0';
        }
    });

    useEffect(() => {
        const storedTheme = localStorage.getItem('thinkmaptheme');
        console.log(`Stored theme: ${storedTheme}`);
        if (storedTheme && themes.includes(storedTheme)) {
            _setTheme(storedTheme);
        }
        else if (prefersDarkMode) {
            setTheme('dark');
        } else {

            setTheme(themes[0]);
        }
    }, []);

    const setDepInnerShadowColor = () => {
        var depInnerShadowColor;
        switch (getTheme()) {
            case 'light':
                depInnerShadowColor = 'rgba(255, 255, 255, 0.5)';
                break;
            case 'dark':
                depInnerShadowColor = 'rgba(0, 0, 0, 0.5)';
                break;
            case 'grey':
                depInnerShadowColor = 'rgba(191, 191, 191, 0.5)';
                break;
            default:
                depInnerShadowColor = 'rgba(255, 255, 255, 0.5)';
        }
        document.documentElement.style.setProperty('--dep-inner-shadow-color', depInnerShadowColor);
    }

    useEffect(() => {
        // set css variables for theme
        document.documentElement.style.setProperty('--bg-color', getBgColor());
        document.documentElement.style.setProperty('--text-color', getTextColor());
        document.documentElement.style.setProperty('--high-contrast-color', getHighContrastColor());
        document.documentElement.style.setProperty('--black-inversion', getBlackInversion());

        setDepInnerShadowColor();
    }, [getTheme()]);

    return (
        <ThemeContext.Provider value={{
            getTheme,
            setTheme,
            cycleTheme,
            getBgColor,
            getTextColor,
            getHighContrastColor,
            getBlackInversion,
            prefersSnapping,
            setPrefersSnapping
        }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    return useContext(ThemeContext);
}
