import "common-styles/App.css";
import React, { Suspense, lazy, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SocketContext, socket } from 'context/socket';
import { MouseContextProvider } from 'context/mouse';
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { useViewportHeight } from "hooks/useViewportHeight";
import { useIsTouchDevice } from "hooks/useIsTouchDevice";
import Authenticator from "common-components/Authenticator";
import { ContextMenuProvider } from "context/ContextMenuContext";
import ContextMenu from "common-components/ContextMenu";
import { ActionDataProvider } from "context/actionData";
import { ThemeContextProvider } from "context/themeContext";
import { KeyboardContextProvider } from "context/keyContext";
import FAQPage from "AboutPage/FAQ";

const AboutPage = lazy(() => import('AboutPage/AboutPage'));
const NextActions = lazy(() => import('NextActions/NextActions'));
const ProjectManager = lazy(() => import('ProjectManager/ProjectManager'));

function App() {
  const isMobile = useCheckMobileScreen();
  const [isLoggedIn, setLoggedIn] = useState(null);
  const [isGoogleLoggedIn, setGoogleLoggedIn] = useState(null);
  const location = useLocation(); // Get the current location
  useViewportHeight(); // Set the --vh css variable
  useIsTouchDevice(); // Add the is-touch-device css class to document body

  // Determine the component to render based on the path
  let ComponentToRender;
  switch (location.pathname) {
    case '/about':
      ComponentToRender = <AboutPage />;
      break;
    case '/about/faq':
      ComponentToRender = <FAQPage />;
      break;
    case '/next':
      ComponentToRender = <NextActions isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} isGoogleLoggedIn={isGoogleLoggedIn} setGoogleLoggedIn={setGoogleLoggedIn} />;
      break;
    default:
      ComponentToRender = isMobile
        ? <NextActions isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} isGoogleLoggedIn={isGoogleLoggedIn} setGoogleLoggedIn={setGoogleLoggedIn} />
        : <ProjectManager isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} isGoogleLoggedIn={isGoogleLoggedIn} setGoogleLoggedIn={setGoogleLoggedIn} />;
  }

  const fallback =
    <>
      <div>Loading...</div>
      <Authenticator isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} isGoogleLoggedIn={isGoogleLoggedIn} setGoogleLoggedIn={setGoogleLoggedIn} className="app-oauth-container" />
    </>
    ;

  return (
    <div className="App">
      <SocketContext.Provider value={socket}>
        <ActionDataProvider>
          <MouseContextProvider>
            <KeyboardContextProvider>
              <ThemeContextProvider>
                <ContextMenuProvider>
                  <Suspense fallback={fallback}>

                    {ComponentToRender}

                    <ContextMenu />

                  </Suspense>
                </ContextMenuProvider>
              </ThemeContextProvider>
            </KeyboardContextProvider>
          </MouseContextProvider>
        </ActionDataProvider>
      </SocketContext.Provider>
    </div>
  );
}

export default App;
