import React, { createContext, useContext, useEffect, useCallback } from 'react';
import { useSync } from 'hooks/useSync';
import {
    useActionListSync, useActionSync,
    useReloadActionListsStore, useReloadActionsStore
} from 'context-utils/helperHooks';
import { SocketContext } from './socket';

const ActionDataContext = createContext();


export const ActionDataProvider = ({ children }) => {
    const socket = useContext(SocketContext);

    const [getActionListsMap, setActionListsMap] = useSync({});
    const [getActionsMap, setActionsMap] = useSync({});

    const { createActionList, updateActionList, deleteActionList } = useActionListSync(setActionListsMap);
    const { createAction, updateAction, bulkUpdateActions, deleteAction } = useActionSync(getActionsMap, setActionsMap, setActionListsMap);

    const reloadActionLists = useReloadActionListsStore(setActionListsMap);
    const reloadActions = useReloadActionsStore(setActionsMap, getActionsMap);

    const getActionList = (id) => getActionListsMap()[id];
    const getAction = (id) => getActionsMap()[id];

    const tryLoadProject = async (projectId) => {
        // ask server if I can load this project. Server should check if "open" is in users and if so it should add me, and then return the
        // actionsList and actions. Then I'll set the actionListsMap and actionsMap to add them
        const { actionList, actions, error } = await new Promise((resolve, reject) => {
            socket.emit('try-load-project', projectId, ({ response: { actionList, actions }, error }) => resolve({ actionList, actions, error }));
        });
        if (error) {
            return { error };
        }

        const newActionsMap = actions.reduce((acc, action) => {
            acc[action.id] = action;
            return acc;
        }, {});

        setActionsMap(prev => ({
            ...prev,
            ...newActionsMap
        }));

        setActionListsMap(prev => ({
            ...prev,
            [actionList.id]: actionList
        }));
    }

    const getSortedLists = () => {
        if (!getActionListsMap) return [];
        // sort by title, but always put the main list first
        const lists = Object.values(getActionListsMap()).sort((a, b) => {
            // sort by rank, then by title
            if (a.rank !== b.rank) {
                return a.rank - b.rank;
            }
            return a.title.localeCompare(b.title);
        });
        const mainListIndex = lists.findIndex(list => list.id.includes('mainlist'));
        if (mainListIndex === -1) {
            return lists;
        }
        else {
            return [lists[mainListIndex], ...lists.slice(0, mainListIndex), ...lists.slice(mainListIndex + 1)];
        }
    }

    const [getSubtaskMap, setSubtaskMap] = useSync({});

    useEffect(() => {
        const subtaskMap = {};
        Object.values(getActionsMap()).forEach(action => {
            if (action.parent) {
                if (!subtaskMap[action.parent]) {
                    subtaskMap[action.parent] = [];
                }
                subtaskMap[action.parent].push(action.id);
            }
        });
        setSubtaskMap(subtaskMap);
    }, [getActionsMap()]);

    const getSubtasks = (actionId) => {
        return getSubtaskMap()[actionId] || [];
    }

    const getSelectableLists = () => {
        return getSortedLists().filter(list => list?.isOnNextActions) || [];
    }

    useEffect(() => {
        const reloadAllHandler = () => { // Ideally the reload all handler's shouldn't be triggered because they require server sending a message to client, then the client requesting data from the server, and then the server sending the data back to the client. Tripling the latency.
            reloadActionLists();
            reloadActions();
        }

        socket.on('reload-all', reloadAllHandler);
        return () => socket.off('reload-all', reloadAllHandler);
    }, [reloadActionLists, reloadActions]);

    return (
        <ActionDataContext.Provider value={{
            createActionList,
            updateActionList,
            deleteActionList,
            createAction,
            updateAction,
            bulkUpdateActions,
            deleteAction,
            getActionList,
            getAction,
            getActionListsMap,
            getActionsMap,
            getSubtasks,
            getSelectableLists,
            tryLoadProject
        }}>
            {children}
        </ActionDataContext.Provider>
    );
};

/**
 * 
 * @returns {{
 * createActionList: (project: { id: string, title: string, isOnProjectPlan: boolean, isOnNextActions: boolean, actions: string[], category: string, rank?: number }) => void,
 * updateActionList: (project: { id: string, title?: string, isOnProjectPlan?: boolean, isOnNextActions?: boolean, actions?: string[], category?: string, rank?: number }) => void,
 * deleteActionList: (projectId: string) => void,
 * createAction: (action: Object) => void,
 * updateAction: (action: Object) => void,
 * deleteAction: (actionId: string) => void,
 * getActionList: (id: string) => { id: string, title: string, isOnProjectPlan: boolean, isOnNextActions: boolean, actions: string[], category: string, rank?: number },
 * getAction: (id: string) => Object,
 * getActionListsMap: () => { [id: string]: { id: string, title: string, isOnProjectPlan: boolean, isOnNextActions: boolean, actions: string[], category: string, rank?: number } },
 * getActionsMap: () => { [id: string]: Object },
 * getSubtasks: (actionId: string) => string[],
 * getSelectableLists: () => { id: string, title: string, isOnProjectPlan: boolean, isOnNextActions: boolean, actions: string[], category: string, rank?: number }[]
 * }}
 */
export const useActionDataContext = () => useContext(ActionDataContext);
