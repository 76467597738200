import { SocketContext } from '../context/socket';
import { useContext, useEffect, useState } from "react";
import useCheckMobileScreen from 'hooks/useCheckMobileScreen';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { GOOGLE_OAUTH_CLIENT_ID } from '../context/config';

export const shouldJoinProject = (path) => {
    const projectMatch = path.match(/\/sharing-is-caring\/join-project\/([a-zA-Z0-9_-]+)/);
    if (projectMatch) {
        return projectMatch[1];
    }
    return null;
}

const Authenticator = ({ isLoggedIn, setLoggedIn, isGoogleLoggedIn, setGoogleLoggedIn, className }) => {
    const isMobile = useCheckMobileScreen();
    const socket = useContext(SocketContext);
    const [hasTriedToJoinProject, setHasTriedToJoinProject] = useState(false);

    const signIn = (credentialResponse) => {
        const jwt = credentialResponse.credential;
        // send jwt to server
        socket.emit('google-jwt', jwt, (response) => {
            if (response instanceof Error) {
                console.warn("Error authenticating with Google: ", response);
                return;
            }
            console.log("Successfully authenticated with Google.");
            setGoogleLoggedIn(true);
        });
    };

    socket.on('login', (googleSuccess, callback) => {
        console.log(`Login info authenticated, server is ready. googleSuccess: ${googleSuccess}`);
        setGoogleLoggedIn(googleSuccess);
        setLoggedIn(true);
        callback(true);
    });

    socket.on('login-failed', (callback) => {
        console.log(`Login info failed to authenticate.`);
        setLoggedIn(false);
        callback(false);
    });

    useEffect(() => {
        const projectToJoin = shouldJoinProject(window.location.pathname);
        if (projectToJoin && !hasTriedToJoinProject && isLoggedIn) {
            console.log("Joining project: ", projectToJoin);
            socket.emit('join-project', projectToJoin, (projectId) => {
                if (!projectId) {
                    console.warn("Error joining project.");
                    // window.location.href = '/';
                }
                console.log("Successfully joined project.");
                // window.location.href = `/?projectId=${projectId}&parent=${projectId}`;
            });
            setHasTriedToJoinProject(true);
        }
    }, [socket, hasTriedToJoinProject, isLoggedIn]);

    useEffect(() => {
        socket.on('redirect-to', (path) => {
            window.location.href = path;
        });

        socket.on('refresh-page', () => {
            window.location.reload();
        });

        return () => {
            socket.off('redirect-to');
            socket.off('refresh-page');
        }
    }, [socket]);


    if (isGoogleLoggedIn === false) {
        return (
            <div className={className}>
                <GoogleOAuthProvider
                    clientId={GOOGLE_OAUTH_CLIENT_ID}
                >
                    <GoogleLogin
                        onSuccess={signIn}
                        onError={error => console.log(error)}
                        useOneTap
                        auto_select
                        type={isMobile ? 'icon' : 'standard'}
                    />
                </GoogleOAuthProvider>
            </div>
        );
    }
}

export default Authenticator;