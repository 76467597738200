import { useState } from "react";

const SimpleLink = ({ to, x, y, children }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div onClick={() => window.location.href = to}
                style={{
                    position: 'fixed',
                    top: y,
                    left: x,
                    padding: '1em',
                    cursor: 'pointer',
                    zIndex: '100',
                    color: '#0000EE',
                    textDecoration: isHovered ? 'underline' : 'none',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {children}
            </div>
    );
}

export default SimpleLink;