import { SocketContext } from "context/socket";
import { useContext, useEffect, useMemo } from "react";

export function useReloadActionListsStore(setActionListsMap) {
    const socket = useContext(SocketContext);

    const reload = () => {
        console.log('useReloadActionListsStore: reloading action lists SHOULD NOT BE TRIGGERED IDEALLY');
        socket.emit('getActionListsMap', (actionListsMap) => {
            setActionListsMap(
                actionListsMap
            );
        });
    }

    useEffect(() => {
        const refreshActionListsMapHandler = (actionListsMap) => {
            setActionListsMap(
                actionListsMap
            );
        }

        const createdActionListHandler = ({ actionList }) => {
            setActionListsMap(prevState => ({
                ...prevState,
                [actionList.id]: actionList
            }));
        }

        const updatedActionListHandler = ({ partialActionList }) => {
            setActionListsMap(prevState => ({
                ...prevState,
                [partialActionList.id]: {
                    ...prevState[partialActionList.id],
                    ...partialActionList
                }
            }));
        }

        const deletedActionListHandler = ({ actionListId }) => {
            setActionListsMap(prevState => {
                const newState = { ...prevState };
                delete newState[actionListId];
                return newState;
            });
        }

        const createdActionHandler = ({ action, actionListId }) => {
            setActionListsMap(prevState => ({
                ...prevState,
                [actionListId]: {
                    ...prevState[actionListId],
                    actions: [...new Set([...prevState[actionListId].actions, action.id])]
                }
            }));
        }

        const deletedActionHandler = ({ actionId, listIds }) => {
            setActionListsMap(prevState => {
                const newState = { ...prevState };
                listIds.forEach(actionListId => {
                    newState[actionListId] = {
                        ...prevState[actionListId],
                        actions: prevState[actionListId].actions.filter(action => action !== actionId)
                    };
                });
                return newState;
            });
        }

        socket.on('refreshActionListsMap', refreshActionListsMapHandler);
        socket.on('createdActionList', createdActionListHandler);
        socket.on('updatedActionList', updatedActionListHandler);
        socket.on('deletedActionList', deletedActionListHandler);

        socket.on('createdAction', createdActionHandler);
        socket.on('deletedAction', deletedActionHandler);

        return () => {
            socket.off('refreshActionListsMap', refreshActionListsMapHandler);
            socket.off('createdActionList', createdActionListHandler);
            socket.off('updatedActionList', updatedActionListHandler);
            socket.off('deletedActionList', deletedActionListHandler);
        }
    }, [socket, setActionListsMap]);

    return reload;
}
export function useReloadActionsStore(setActionsMap, getActionsMap) {
    const socket = useContext(SocketContext);

    const reload = () => {
        console.log('useReloadActionsStore: reloading actions SHOULD NOT BE TRIGGERED IDEALLY');
        socket.emit('getActionsMap', (actionsMap) => {
            setActionsMap(
                actionsMap
            );
        });
    }

    useEffect(() => {
        const refreshActionsMapHandler = (actionsMap) => {
            setActionsMap(
                actionsMap
            );
        }

        const createdActionHandler = ({ action }) => {
            setActionsMap(prevState => ({
                ...prevState,
                [action.id]: action
            }));
        }

        const updatedActionHandler = ({ partialAction }) => {
            setActionsMap(prevState => ({
                ...prevState,
                [partialAction.id]: {
                    ...prevState[partialAction.id],
                    ...partialAction
                }
            }));
        }

        const bulkUpdateActionsHandler = ({ partialActions }) => {
            setActionsMap(prevState => {
                const newState = { ...prevState };
                partialActions.forEach(partialAction => {
                    newState[partialAction.id] = {
                        ...prevState[partialAction.id],
                        ...partialAction
                    };
                });
                return newState;
            });
        }

        const deletedActionHandler = ({ actionId }) => {
            setActionsMap(prevState => {
                const newState = { ...prevState };
                delete newState[actionId];
                return newState;
            });
        }

        socket.on('refreshActionsMap', refreshActionsMapHandler);
        socket.on('createdAction', createdActionHandler);
        socket.on('updatedAction', updatedActionHandler);
        socket.on('bulkUpdatedActions', bulkUpdateActionsHandler);
        socket.on('deletedAction', deletedActionHandler);

        return () => {
            socket.off('refreshActionsMap', refreshActionsMapHandler);
            socket.off('createdAction', createdActionHandler);
            socket.off('updatedAction', updatedActionHandler);
            socket.off('bulkUpdatedActions', bulkUpdateActionsHandler);
            socket.off('deletedAction', deletedActionHandler);
        }
    }, [socket, setActionsMap]);

    return reload;
}

export function useActionListSync(setActionListsMap) {
    const socket = useContext(SocketContext);

    const createActionList = (actionList) => {
        socket.emit('createActionList',
            actionList,
            ({ response, error }) => {
                if (error) {
                    console.error(`Error creating action list: ${error}`);
                    return;
                }
            }
        );

        setActionListsMap(prevState => ({
            ...prevState,
            [actionList.id]: actionList
        }));
    };

    const updateActionList = (partialActionList) => {
        socket.emit('updateActionList',
            partialActionList,
            ({ response, error }) => {
                if (error) {
                    console.error(`Error updating action list: ${error}`);
                    return;
                }
            }
        );

        setActionListsMap(prevState => ({
            ...prevState,
            [partialActionList.id]: {
                ...prevState[partialActionList.id],
                ...partialActionList
            }
        }));
    };

    const deleteActionList = (actionListId) => {
        socket.emit('deleteActionList',
            actionListId,
            ({ response, error }) => {
                if (error) {
                    console.error(`Error deleting action list: ${error}`);
                    return;
                }
            }
        );

        setActionListsMap(prevState => {
            const newState = { ...prevState };
            delete newState[actionListId];
            return newState;
        });
    };

    return {
        createActionList,
        updateActionList,
        deleteActionList
    };
}

export function useActionSync(getActionsMap, setActionsMap, setActionListsMap) {
    const socket = useContext(SocketContext);

    const createAction = (action, actionListId) => {
        // Update actions map immediately
        setActionsMap(oldActionsMap => ({
            ...oldActionsMap,
            [action.id]: action
        }));

        // Update action lists map immediately
        setActionListsMap(oldActionListsMap => ({
            ...oldActionListsMap,
            [actionListId]: {
                ...oldActionListsMap[actionListId],
                actions: [...oldActionListsMap[actionListId].actions, action.id]
            }
        }));

        // Return a promise for the server response part
        return new Promise((resolve, reject) => {
            socket.emit('createAction', action, actionListId, ({ response, error }) => {
                if (error) {
                    console.error(`Error creating action: ${error}`);
                    reject(new Error(`Error creating action: ${error}`));
                    return;
                }
                resolve(response);
            });
        });
    };


    const updateAction = (partialAction, emit = true) => {
        if (emit) {
            socket.emit('updateAction',
                partialAction,
                ({ response, error }) => {
                    if (error) {
                        console.error(`Error updating action: ${error}`);
                        return;
                    }
                }
            );
        }

        setActionsMap(oldActionsMap => ({
            ...oldActionsMap,
            [partialAction.id]: {
                ...oldActionsMap[partialAction.id],
                ...partialAction
            }
        }));
    }

    const bulkUpdateActions = (partialActions, emit) => {
        if (emit) {
            socket.emit('bulkUpdateActions',
                partialActions,
                ({ response, error }) => {
                    if (error) {
                        console.error(`Error bulk updating actions: ${error}`);
                        return;
                    }
                }
            );
        }

        setActionsMap(oldActionsMap => {
            const newActionsMap = { ...oldActionsMap };
            partialActions.forEach(partialAction => {
                newActionsMap[partialAction.id] = {
                    ...oldActionsMap[partialAction.id],
                    ...partialAction
                };
            });
            console.log('bulkUpdateActions\n', Object.keys(oldActionsMap).map(
                id => `\t${oldActionsMap[id].title}: ${oldActionsMap[id].rank} -> ${newActionsMap[id].rank}`
            ).join('\n')
            );
            return newActionsMap;
        })
    }

    const deleteAction = (actionId) => {
        socket.emit('deleteAction',
            actionId,
            ({ response, error }) => {
                if (error) {
                    console.error(`Error deleting action: ${error}`);
                    return;
                }
            }
        );

        const childMap = Object.entries(getActionsMap())
            .reduce((acc, [id, action]) => {
                if (action.parent) {
                    if (acc[action.parent]) {
                        acc[action.parent].push(id);
                    } else {
                        acc[action.parent] = [id];
                    }
                }
                return acc;
            }, {});

        // Find all children (recursively) of actionId
        let children = childMap[actionId] || [];
        let isChild = {};
        while (children.length > 0) {
            const child = children.pop();
            isChild[child] = true;
            children = children.concat(childMap[child] || []);
        }

        setActionListsMap(oldActionListsMap => {
            const newActionListsMap = Object.entries(oldActionListsMap).reduce((acc, [id, actionList]) => {
                acc[id] = {
                    ...actionList,
                    actions: actionList.actions.filter(
                        action =>
                            action !== actionId
                            && !isChild[action]
                    )
                };
                return acc;
            }, {});

            return newActionListsMap;
        });

        setActionsMap(oldActionsMap => {
            // remove from all dependencies
            return Object.entries(oldActionsMap).reduce((acc, [id, action]) => {
                if (id === actionId || isChild[id]) {
                    return acc;
                }

                acc[id] = {
                    ...action,
                    dependencies: action.dependencies?.filter(dep => dep !== actionId) || []
                };
                return acc;
            }, {});
        });
    }

    return {
        createAction,
        updateAction,
        bulkUpdateActions,
        deleteAction
    };
}
