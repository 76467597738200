import { useEffect } from 'react';

export const useViewportHeight = () => {
    useEffect(() => {
        const updateHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        window.addEventListener('resize', updateHeight);
        updateHeight(); // Set the initial height

        return () => window.removeEventListener('resize', updateHeight);
    }, []);
};
