import { useEffect } from "react";
import { useSync } from "./useSync";

/**
 * A combination of useState and useRef, to allow for stable state handling inside handlers and other async functions.
 * @param {*} initialValue 
 * @returns [getState, setState]
 */
export function useFeed(getFoodValue, func=(v)=>v) {
    const [getValue, setValue] = useSync(getFoodValue);

    const feed = () => {
        const newValue = func(getFoodValue());
        if (newValue !== getValue()) {
            setValue(newValue);
        }
    }

    feed();

    useEffect(() => {
        feed();
    }, [getValue()]);

    return getValue;
}
