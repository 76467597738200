
import { useSync } from 'hooks/useSync';
import React, { createContext, useEffect, useContext, useRef } from 'react';

const KeyboardContext = createContext();


export const KeyboardContextProvider = ({ children }) => {
    const [ getIsPressed, setIsPressed ] = useSync({});
    const [ getHistory, setHistory ] = useSync([]);
    const historyIndex = useRef(-1);

    const addHistory = ({key, undoFn, redoFn}) => {
        setHistory([...getHistory().slice(0, historyIndex.current + 1), {key, undoFn, redoFn}]);
        historyIndex.current += 1;
    }

    const undo = (e) => {
        if (historyIndex.current > -1) {
            e.preventDefault();
            getHistory()[historyIndex.current].undoFn(e);
            historyIndex.current -= 1;
        }
    }

    const redo = (e) => {
        if (historyIndex.current < getHistory().length - 1) {
            e.preventDefault();
            historyIndex.current += 1;
            getHistory()[historyIndex.current].redoFn(e);
        }
    }

    const isPressed = (key) => {
        return getIsPressed()[key];
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'z' && (e.ctrlKey || e.metaKey)) {
                if (e.shiftKey) {
                    redo(e);
                } else {
                    undo(e);
                }
            } if (e.key === 'Z' && (e.ctrlKey || e.metaKey) && e.shiftKey) {
                redo(e);
            }
            setIsPressed({ ...getIsPressed(), [e.key]: true });
        }
        const handleKeyUp = (e) => {
            setIsPressed({ ...getIsPressed(), [e.key]: false });
        }
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        }
    }, []);

    return (
        <KeyboardContext.Provider value={{
            isPressed,
            addHistory
        }}>
            {children}
        </KeyboardContext.Provider>
    );
};

export const useKeyboardContext = () => {
    return useContext(KeyboardContext);
}
