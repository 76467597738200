/**
 * Custom hook to determine if the current device is a touch screen device.
 * @returns {boolean} True if the device is a touch screen device, false otherwise.
 */
export const useIsTouchDevice = () => {
    // Check if the device supports coarse pointer (touch) input
    const isTouchDevice = window.matchMedia('(pointer: coarse)').matches;

    document.body.classList.toggle('is-touch-device', isTouchDevice);
  
    return isTouchDevice;
};
  