import React, { useState } from 'react';
import SimpleLink from 'common-components/SimpleLink';

const Q = ({ children }) => {
    return (
        <p style={{ margin: '0.5em 0.5em 0em 0em', fontStyle: 'italic' }}>{children}</p>
    );
}

const A = ({ children }) => {
    return (
        <p style={{ margin: '0.1em 0.5em 0.5em 0.5em', fontWeight: 'bold' }}>{children}</p>
    );
}

const FAQPage = ({ }) => {
    return (
        <>
            <SimpleLink to="/" x="0" y="0">
                Thinkmap
            </SimpleLink>
            <SimpleLink to="/about" x="200px" y="0">
                About
            </SimpleLink>
            <div
                display="flex"
                style={{
                    marginTop: '3em',
                    padding: '2em',
                    maxWidth: '1000px',
                    overflow: 'scroll',
                    height: '100%',
                }}
            >
                <h1>Thinkmap - <i>be context confident.</i></h1>
                <h2>FAQ <i>(In progress)</i></h2>
                <Q>So... What is it?</Q>
                <A>Thinkmap is a simple project planning tool which allows you to organize tasks primarily in two ways: subtasks and dependencies. Double click a task to break it down into the smaller tasks that compose it. Drag arrows between tasks to indicate the order in which they must be done. That's it. I've found this system of organization to be uniquely useful in jotting out my thoughts to maintain a kind of working context on my myriad projects. </A>
                <Q>How do I remove a dependency?</Q>
                <A>Re-drag an arrow between the tasks you want to unlink.</A>
                <Q>What are the colors about?</Q>
                <A>The color of each task is determined by an AI based on a mixture three factors: Red means uncertain, Green means practical, Blue means informational.</A>
                <Q>How do I mark something in progress?</Q>
                <A>Hover over the botton of a task and press to show a progress bar. The progress will percolate up to the parent. Click the bar again to advance the progress.</A>
                <Q>How do I copy/paste?</Q>
                <A>Ctrl+C/Ctrl+V or Cmd+C/Cmd+V while hovering. You can also right click, which brings up a menu with other useful features, like check/uncheck all children, and you can convert a task to text.</A>
                <Q>Does it cost money? What do you do with my google log in information?</Q>
                <A>Nope. We don't do anything with the information we get from google log in, aside from use it to authenticate you so you can sign in from anywhere and access your Thinkmap. </A>
                <h3
                    style={{
                        marginTop: '0.2em',
                        marginBottom: '0.2em',
                    }}
                >Contact</h3>
                <p>Please, email <a href="mailto:thinkmapai@gmail.com">thinkmapai@gmail.com</a> with feedback, bug reports, or suggestions. I love to hear how using Thinkmap helps you get things done!</p>
                <p>Check out my <a href="https://www.linkedin.com/in/miles-wiesenthal/">LinkedIn</a> to connect and stay updated (Frequent updates.) </p>
            </div>
        </>
    );
};

export default FAQPage;