import React, { createContext, useContext, useEffect, useState } from 'react';

const ContextMenuContext = createContext();

export const ContextMenuProvider = ({ children }) => {
    const [contextMenuState, setContextMenuState] = useState({
        isVisible: false,
        position: { x: 0, y: 0 },
        items: []
    });

    const showContextMenu = (x, y, items) => {
        setContextMenuState({
            isVisible: true,
            position: { x, y },
            items
        });
    };

    const hideContextMenu = () => {
        setContextMenuState(prevState => ({
            ...prevState,
            isVisible: false
        }));
    };

    return (
        <ContextMenuContext.Provider value={{ contextMenuState, showContextMenu, hideContextMenu }}>
            {children}
        </ContextMenuContext.Provider>
    );
};

export const useContextMenu = () => useContext(ContextMenuContext);
