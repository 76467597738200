import { useState, useEffect } from 'react';

const useLocalStorage = (key, initialState) => {
    const [state, setState] = useState(() => {
        try {
            const storedState = window.localStorage.getItem(key);
            console.log(`Stored state: ${storedState}`);
            return storedState ? JSON.parse(storedState) : initialState;
        }
        catch (e) {
            console.error(e);
            return initialState;
        }
    });

    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(state));
        }
        catch (e) {
            console.error(e);
        }
    }, [key, state]);

    return [state, setState];
};

export default useLocalStorage;
