import { useEffect, useState } from "react";

const useCheckMobileScreen = () => {
    const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth <= window.innerHeight * 0.9);

    useEffect(() => {
        const handleWindowSizeChange = () => {
            const currentIsMobileScreen = window.innerWidth <= window.innerHeight * 0.9;
            if (currentIsMobileScreen !== isMobileScreen) {
                setIsMobileScreen(currentIsMobileScreen);
            }
        };

        window.addEventListener('resize', handleWindowSizeChange);
        
        // Initial check in case the screen size already meets the mobile condition
        handleWindowSizeChange();

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [isMobileScreen]); // Dependency on isMobileScreen to ensure updates are based on its change

    return isMobileScreen;
}

export default useCheckMobileScreen;
